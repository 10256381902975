import React, { Component } from "react";
// import axios from 'axios';
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import { createStore, applyMiddleware, compose } from 'redux';
// import { Provider } from 'react-redux';
// import reduxThunk from 'redux-thunk';

// import { renderRoutes } from 'react-router-config';
// import './App.scss';
// import { reConnect } from './actions/AuthActions';
// import reducers from './reducers';
// import { composeWithDevTools } from 'redux-devtools-extension';

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const Layout = React.lazy(() => import("./components/layouts/Layout"));

// Pages
// const Login = React.lazy(() => import('./views/Pages/Login'));
// const Page404 = React.lazy(() => import('./views/Pages/Page404'));
// const Page500 = React.lazy(() => import('./views/Pages/Page500'));

// const appStore = createStore(
//   reducers,
//   // composeWithDevTools(
//   //   applyMiddleware(reduxThunk)
//   // )

//   compose(
//     applyMiddleware(reduxThunk)
//   )
// );

// axios.interceptors.response.use(function (response) {
//   return response;
// }, function (error) {
//     if (401 === error.response.status) {
//         appStore.dispatch(reConnect());
//     } else {
//         return Promise.reject(error);
//     }
// });

class App extends Component {
  render() {
    return (
      //   <Provider store={appStore}>
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            {/* <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} /> */}
            <Route
              path="/"
              name="Home"
              render={(props) => <Layout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
      //   </Provider>
    );
  }
}

export default App;
